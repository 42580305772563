import { Component, Prop } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'

@Component
export default class FadeTransition extends tsx.Component<{
  duration?: number
}> {
  @Prop({ type: Number, default: 0.2 })
  readonly duration!: number

  lastTransitionStartedAt = 0

  async onEnter(el: Element, done: () => void) {
    this.lastTransitionStartedAt = new Date().getTime()

    const htmlEl = el as HTMLElement
    htmlEl.style.transition = `${this.duration}s opacity ease`
    htmlEl.style.opacity = '0'
    setTimeout(() => {
      htmlEl.style.opacity = '1'
    }, 20)

    setTimeout(() => {
      done()

      if (
        new Date().getTime() - this.lastTransitionStartedAt >=
        this.duration * 1000
      ) {
        htmlEl.style.removeProperty('transition')
        htmlEl.style.removeProperty('opacity')
      }
    }, this.duration * 1000)
  }

  async onLeave(el: Element, done: () => void) {
    this.lastTransitionStartedAt = new Date().getTime()

    const htmlEl = el as HTMLElement
    htmlEl.style.transition = `${this.duration}s opacity ease`
    htmlEl.style.opacity = '0'

    setTimeout(done, this.duration * 1000)
  }

  protected render() {
    return (
      <transition
        css={false}
        onEnter={this.onEnter}
        onLeave={this.onLeave}
        mode="out-in"
      >
        {this.$slots.default}
      </transition>
    )
  }
}
