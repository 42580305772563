import Select from '@/components/controls/Select'
import Link from '@/components/Link'
import WayupTable from '@/components/WayupTable'
import CourseCell from '@/components/WayupTable/cellTemplates/Course'
import UserCell from '@/components/WayupTable/cellTemplates/User'
import { CellData, Column, FetchFunction } from '@/components/WayupTable/types'
import { DateString } from '@/helpers/types/dateString'
import { Nullable } from '@/helpers/types/nullable'
import axios from '@/libs/axios'
import { ICourse, IMentor, IStream } from '@/views/Orders'
import { AxiosResponse } from 'axios'
import { Component, Prop, Ref, Watch } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'

import 'wayup-phone-input/dist/wayup-phone-input.css'
import './style.scoped.scss'

import StudentProgressModal from '@/components/modals/StudentProgressModal.vue'
import StreamSelectTemplate from '@/components/controls/Select/templates/Stream'
import UserSelectTemplate from '@/components/controls/Select/templates/User'
import { showError, showMessage } from '@/helpers/notifications'
import ContextMenu from '@/components/ContextMenu'
import { BButton } from '@/components/bootstrap'
import DeleteConfirmationModal from '@/components/DeleteConfirmationModal'
import { LearningPlan } from '@/enums/LearningPlan'
const OrderPaymentsModal =
  require('@/components/students/OrderPaymentsModal.vue').default

interface IOrder {
  id: number
  email: string
  fullName: string
  phone: string
  avatar?: string
  course: {
    id: number
    title: string
    abbreviation: string
    previewUrl: string
    previewColor: string
    type: 'free' | 'paid'
    progress: {
      current: number
      all: number
    }
    points: {
      current: number
      all: number
    }
  }
  stream: IStream
  access: boolean
  price: Nullable<number>
  orderId: number
  accessId: number
  amount: Nullable<number>
  finalPayment: Nullable<DateString>
  mentor: IMentor
  plan: LearningPlan
  streamRegistrationDate: string
}

interface ICertiicate {
  courseId: number
  id: number
  urls: {
    ru?: string
    ua?: string
    en?: string
  }
}

interface IOrdersTableProps {
  courses: ICourse[]
  streams: IStream[]
  mentors: IMentor[]
  selectedStreamId?: number
  selectedCourseId?: number
  studentId?: number
  initialized?: boolean
  onUpdatePending?: (value: boolean) => void
  onPaymentAdded?: () => void
}

interface PlanOption {
  label: string
  value: LearningPlan
}

@Component
export default class OrdersTable extends tsx.Component<IOrdersTableProps> {
  @Ref() readonly deleteConfirmationModal!: DeleteConfirmationModal
  @Ref() readonly table!: WayupTable
  @Ref() readonly orderPaymentsModal!: {
    show: ({ email, orderId }: { email: string; orderId: number }) => void
  }
  @Ref() readonly studentProgressModal!: {
    show: (studentId: number, streamId: number, studentName: string) => void
  }

  @Prop({ type: Array, required: true })
  readonly streams!: IStream[]
  @Prop({ type: Array, required: true })
  readonly mentors!: IMentor[]
  @Prop({ type: Array, required: true })
  readonly courses!: ICourse[]
  @Prop({ type: Number })
  readonly selectedStreamId!: Number
  @Prop({ type: Number })
  readonly selectedCourseId!: Number
  @Prop({ type: Number })
  readonly studentId?: Number
  @Prop({ type: Boolean, default: true })
  readonly initialized!: boolean

  certificates: ICertiicate[] = []
  plans: PlanOption[] = [
    {
      label: 'Стандарт',
      value: LearningPlan.STANDARD,
    },
    {
      label: 'Премиум',
      value: LearningPlan.PREMIUM,
    },
    {
      label: 'Легенда',
      value: LearningPlan.LEGEND,
    },
  ]

  get columns() {
    const columns: Column<IOrder>[] = [
      {
        field: 'fullName',
        title: 'Имя/Фамилия',
        sortable: true,
      },
      {
        field: 'email',
        title: 'Email',
        sortable: true,
        editable: {
          inputEvent: value => {
            return !/\S+@\S+\.\S+/.test(value) ? false : undefined
          },
          save: async (value, row) => {
            const result = await this.saveStudentInfo('email', value, row)
            if (result) {
              row.email = value
            }
            return result
          },
        },
        style: 'min-width: 170px',
      },
      {
        field: 'phone',
        title: 'Телефон',
        type: 'phone',
        editable: {
          save: async (value, row) => {
            if (value === row.phone) {
              return
            }

            const result = await this.saveStudentInfo('phone', value, row)
            if (result) {
              row.phone = value
            }
            return result
          },
        },
        sortable: true,
      },
      {
        field: 'course',
        title: 'Курс',
        sortable: true,
      },
      {
        field: 'stream',
        title: 'Поток',
        sortable: true,
        style: 'max-width: 200px; padding: 0 !important;',
      },
      {
        field: 'streamRegistrationDate',
        title: 'Дата регистрации',
        type: 'dateTime',
        sortable: true,
      },
      {
        customField: 'progress',
        title: 'Прогресс',
        centered: true,
        noWrap: true,
      },
      {
        customField: 'points',
        title: 'Баллы',
        centered: true,
        noWrap: true,
      },
      {
        customField: 'certificates',
        title: 'Сертификаты',
        centered: true,
      },
      {
        field: 'access',
        title: 'Доступ',
        type: 'switcher',
        switcherVariant: 'red-green',
        editable: {
          save: async (value, row) => {
            const result = await this.saveStudentInfo('access', value, row)
            if (result) {
              row.access = value
            }
            return result
          },
        },
        sortable: true,
      },
      {
        field: 'mentor',
        title: 'Ментор',
        sortable: true,
        style: 'min-width: 230px; padding: 0 !important;',
      },
      {
        field: 'plan',
        title: 'Тариф',
        sortable: true,
      },
      {
        field: 'price',
        title: 'Цена $',
        type: 'number',
        centered: true,
        sortable: true,
        editable: {
          inputEvent: value => {
            return !value || value < 0 ? false : undefined
          },
          save: async (value, row) => {
            const result = await this.saveStudentInfo('price', value, row)
            if (result) {
              row.price = value
            }
            return result
          },
        },
        style: 'min-width: 100px',
      },
      {
        field: 'amount',
        title: 'Оплачено $',
        sortable: true,
        centered: true,
      },
      {
        field: 'finalPayment',
        title: 'Финальный платёж',
        type: 'date',
        sortable: true,
        editable: {
          save: async (value, row) => {
            const result = await this.saveStudentInfo(
              'final_payment',
              value,
              row,
            )
            if (result) {
              row.finalPayment = value
            }
            return result
          },
        },
        style: ({ row }) => {
          if (!this.checkDate(row)) {
            return 'background: rgba(255,0,0,.3) !important'
          }
        },
      },
      {
        customField: 'buttons',
        centered: true,
        noWrap: true,
      },
    ]

    if (this.studentId) {
      return columns.filter(
        c =>
          c.field !== 'fullName' && c.field !== 'email' && c.field !== 'phone',
      )
    }

    return columns.filter(c => c.customField !== 'certificates')
  }

  @Watch('streams')
  @Watch('selectedStreamId')
  @Watch('selectedCourseId')
  refreshTable() {
    if (this.initialized) {
      this.table.refresh()
    }
  }

  async mounted() {
    if (this.studentId) {
      await this.fetchCertificates()
    }
  }

  async fetchCertificates() {
    try {
      const response: AxiosResponse<{
        certificates: ICertiicate[]
      }> = await axios.get(`v1/students/${this.studentId}/certificates`)

      this.certificates = response.data.certificates
    } catch (error) {
      console.error(error)
      showError('Произошла ошибка при загрузке сертификатов')
    }
  }

  async saveStudentInfo(field: string, value: any, row: IOrder) {
    try {
      await axios.patch('v1/students/table/update', {
        [field]: value,
        studentId: row.id,
        streamId: row.stream.id,
      })

      return true
    } catch (error) {
      console.error(error)
      return false
    }
  }

  loadOrders: FetchFunction<IOrder> = async (page, perPage, search, sort) => {
    const props = (this as typeof this & { _props: IOrdersTableProps })._props

    if (!props.streams) {
      return { rows: [], totalRows: 0 }
    }

    const searchQuery = search === '' ? '' : `&searchQuery=${search}`

    const studentFilter = props.studentId
      ? ` &filter=${props.studentId}&filterOption=id`
      : ''

    const orderBy = sort
      ? `&sortBy=${sort.field}&sortDir=${sort.dir}`
      : `&sortBy=orderId&sortDir=desc`

    const courseQuery = props.selectedCourseId
      ? `&course=${props.selectedCourseId}`
      : ''

    const streamQuery = props.selectedStreamId
      ? `&stream=${props.selectedStreamId}`
      : ''

    const response: AxiosResponse<{
      data: IOrder[]
      meta: {
        count: number
        currentPage: number
        hasMorePages: boolean
        hasPages: boolean
        lastPage: number
        totalItems: number
      }
    }> = await axios.get(
      `v1/students-pagination?page=${page}&perPage=${perPage}${searchQuery}${orderBy}${courseQuery}${streamQuery}${studentFilter}`,
    )

    const orders = response.data.data

    for (const order of orders) {
      const mentor = props.mentors.find(m => m.id === order.mentor?.id)
      if (mentor) {
        order.mentor = mentor
      }

      const stream = props.streams.find(s => s.id === order.stream?.id)
      if (stream) {
        order.stream = stream
      }

      const course = props.courses.find(c => c.id === order.course?.id)

      if (course) {
        order.course.type = course.type
      }
    }

    return {
      rows: orders,
      totalRows: response.data.meta.totalItems,
    }
  }

  getStreamsForCourse(courseId: number) {
    return this.streams
      .filter(s => s.courseId === courseId)
      .sort((s1, s2) => {
        const date1 = new Date(s1.dateStart).getTime()
        const date2 = new Date(s2.dateStart).getTime()

        return date2 - date1
      })
  }

  getMentorsForCourse(courseId: number) {
    return this.mentors.filter(m => m.courses.includes(courseId))
  }

  onStreamUpdate(stream: IStream, cellData: CellData<IOrder>) {
    cellData.saveFn(async () => {
      const result = await this.saveStudentInfo(
        'newStreamId',
        stream.id,
        cellData.row,
      )

      cellData.row.stream = stream

      return result
    })
  }

  onMentorUpdate(mentor: IMentor, cellData: CellData<IOrder>) {
    cellData.saveFn(async () => {
      const result = await this.saveStudentInfo(
        'mentorId',
        mentor.id,
        cellData.row,
      )

      cellData.row.mentor = mentor

      return result
    })
  }

  onPlanUpdate(plan: LearningPlan, cellData: CellData<IOrder>) {
    cellData.saveFn(async () => {
      const result = await this.saveStudentInfo('plan', plan, cellData.row)

      cellData.row.plan = plan

      return result
    })
  }

  openPayments(row: IOrder) {
    this.orderPaymentsModal.show({
      email: row.email,
      orderId: row.orderId,
    })
  }

  openProgress(row: IOrder) {
    this.studentProgressModal.show(row.id, row.stream.id, row.fullName)
  }

  checkDate(row: IOrder) {
    return (
      row.amount === row.price ||
      new Date(row.finalPayment!).getTime() - Date.now() > 0
    )
  }

  getProgressString({ current, all }: { current: number; all: number }) {
    return `${current} из ${all}`
  }

  async deleteCertificate(id: number, lang: string, courseTitle: string) {
    let language = 'русский'

    switch (lang) {
      case 'en':
        language = 'английский'
        break
      case 'ua':
        language = 'украинский'
        break
    }

    try {
      const result = await this.$bvModal.msgBoxConfirm(
        `Вы действительно хотите удалить сертификат за прохождение курса "${courseTitle}", язык - ${language}?`,
        {
          title: 'Подтверждение удаления',
          centered: true,
          okTitle: 'Удалить',
          cancelTitle: 'Отмена',
        },
      )

      if (!result) {
        return
      }

      await axios.delete(`v1/certificates/${id}?lang=${lang}`)
      await this.fetchCertificates()
      return true
    } catch (error) {
      console.error(error)
      showError('При удалении сертификата произошла ошибка')
      return false
    }
  }

  async deleteOrder(order: IOrder) {
    const result = await this.deleteConfirmationModal.show(
      order.accessId.toString(),
    )

    if (result) {
      try {
        await axios.delete(`v1/courses/access/${order.accessId}`)

        this.table.modifyRows((orders: IOrder[]) => {
          return orders.filter(o => o.accessId !== order.accessId)
        })

        showMessage('Заказ удалён', 'Успех')
      } catch (error) {
        console.error(error)
        showError('Произошла ошибка при удалении заказа')
      }
    }
  }

  cellTemplate(cellData: CellData<IOrder>) {
    switch (cellData.column.customField) {
      case 'buttons':
        return (
          <div>
            <BButton
              onClick={() => this.deleteOrder(cellData.row)}
              variant="outline-danger"
              class="p-50"
            >
              <feather-icon icon="TrashIcon" size="16" />
            </BButton>
          </div>
        )
      case 'progress':
        return (
          <b
            onClick={() => this.openProgress(cellData.row)}
            class="text-primary cursor-pointer"
          >
            {this.getProgressString(cellData.row.course.progress)}
          </b>
        )
      case 'points':
        return <span>{this.getProgressString(cellData.row.course.points)}</span>
      case 'certificates':
        const certificate = this.certificates.find(
          c => c.courseId === cellData.row.course.id,
        )
        if (!certificate || !Object.values(certificate.urls).length) {
          return '-'
        }

        return Object.entries(certificate.urls).map(([key, value]) => (
          <span class="flag">
            <img src={require(`@/assets/images/flags/${key}.svg`)} alt={key} />
            <ContextMenu
              leftBtn
              options={[
                {
                  label: 'Скачать',
                  icon: 'DownloadIcon',
                  action: () => {
                    window.open(value)
                  },
                },
                {
                  label: 'Удалить',
                  icon: 'Trash2Icon',
                  action: () =>
                    cellData.saveFn(() =>
                      this.deleteCertificate(
                        certificate.id,
                        key,
                        cellData.row.course.title,
                      ),
                    ),
                },
              ]}
            />
          </span>
        ))
    }

    switch (cellData.column.field) {
      case 'fullName':
        return (
          <Link to={`/students/${cellData.row.id}`}>
            <UserCell
              avatar={cellData.row.avatar}
              firstRow={cellData.row.fullName}
            />
          </Link>
        )
      case 'course':
        return (
          <CourseCell
            title={cellData.row.course.abbreviation}
            image={cellData.row.course.previewUrl}
            color={cellData.row.course.previewColor}
            tooltip={cellData.row.course.title}
          />
        )
      case 'stream':
        return (
          <Select
            value={cellData.value}
            onInput={(stream: IStream) => this.onStreamUpdate(stream, cellData)}
            options={this.getStreamsForCourse(cellData.row.course.id)}
            placeholder="выберите поток"
            emptyFilterMessage="нет подходящих потоков"
            clearable={false}
            label="title"
            flat
            appendToBody
            scopedSlots={{
              option: ({ option }: { option: IStream }) => (
                <StreamSelectTemplate
                  title={option.title}
                  dateStart={option.dateStart}
                />
              ),
            }}
          />
        )
      case 'mentor':
        return (
          <Select
            value={cellData.value}
            onInput={(mentor: IMentor) => this.onMentorUpdate(mentor, cellData)}
            options={this.getMentorsForCourse(cellData.row.course.id)}
            placeholder="выберите ментора"
            emptyFilterMessage="нет подходящих менторов"
            clearable={false}
            label="fullName"
            flat
            appendToBody
            style="height: 100%;"
            scopedSlots={{
              selectedOption: (option: IMentor) => (
                <UserSelectTemplate
                  name={option.fullName}
                  avatar={option.avatar}
                />
              ),
              option: ({ option }: { option: IMentor }) => (
                <UserSelectTemplate
                  name={option.fullName}
                  avatar={option.avatar}
                />
              ),
            }}
          />
        )
      case 'plan':
        return (
          <Select
            value={{
              label: this.plans.find(p => p.value === cellData.value)?.label,
              value: cellData.value,
            }}
            onInput={(plan: PlanOption) =>
              this.onPlanUpdate(plan.value, cellData)
            }
            options={this.plans}
            disabled={
              cellData.row.course.abbreviation.toUpperCase() !== 'ВДСБТ'
            }
            placeholder="выберите тариф"
            emptyFilterMessage="нет подходящих тарифов"
            clearable={false}
            flat
            appendToBody
            style="height: 100%;"
          />
        )
      case 'price':
        return cellData.row.course.type === 'paid' ? null : '-'
      case 'amount':
        return cellData.row.course.type === 'paid' ? (
          <b
            class="text-primary cursor-pointer"
            onClick={() => this.openPayments(cellData.row)}
          >
            {cellData.row.amount}
          </b>
        ) : (
          '-'
        )
      case 'finalPayment':
        return cellData.row.course.type === 'paid' ? null : '-'
    }
  }

  protected render() {
    return (
      <div>
        <WayupTable
          ref="table"
          fetchFunction={this.loadOrders}
          columns={this.columns}
          searchEnabled={!this.studentId}
          paginationEnabled
          perPage={20}
          fetchOnMount={false}
          scopedSlots={{ default: cellData => this.cellTemplate(cellData) }}
          on={{
            'update:pending': (value: boolean) =>
              this.$emit('updatePending', value),
          }}
        />
        <DeleteConfirmationModal ref="deleteConfirmationModal" />
        <StudentProgressModal ref="studentProgressModal" />
        <OrderPaymentsModal
          ref="orderPaymentsModal"
          onRefresh={() => {
            this.refreshTable()
            this.$emit('paymentAdded')
          }}
        />
      </div>
    )
  }
}
