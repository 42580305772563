import { DateString } from '@/helpers/types/dateString'
import { Component, Prop } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import './style.scoped.scss'

interface ITemplateProps {
  title: string
  dateStart: DateString
}

@Component
export default class StreamSelectTemplate extends tsx.Component<
  ITemplateProps
> {
  @Prop({ type: String, required: true })
  readonly title!: string

  @Prop({ type: String, required: true })
  readonly dateStart!: DateString

  get date() {
    return new Date(this.dateStart).toLocaleDateString('ru', {
      year: 'numeric',
      day: '2-digit',
      month: '2-digit',
    })
  }

  protected render() {
    return (
      <div class="d-flex align-items-center option">
        <div class="title">
          <div>{this.title}</div>
          <div class="bottom-line">
            <feather-icon size="15" icon="CalendarIcon" />
            <small> {this.date}</small>
          </div>
        </div>
      </div>
    )
  }
}
