import { Component, Prop } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import './style.scoped.scss'

interface ITemplateProps {
  name: string
  email?: string
  avatar?: string | null
  size?: 'sm' | 'md'
}

@Component
export default class UserSelectTemplate extends tsx.Component<ITemplateProps> {
  @Prop({ type: String, required: true })
  readonly name!: string
  @Prop({ type: String })
  readonly email!: string
  @Prop({ type: String })
  readonly avatar?: string
  @Prop({ type: String, default: 'md' })
  readonly size?: string

  protected render() {
    return (
      <div class="d-flex align-items-center option">
        <b-avatar src={this.avatar} size={this.size} class="mr-50" />
        <div class="title">
          <div>{this.name}</div>
          <div class="bottom-line">
            <small>{this.email}</small>
          </div>
        </div>
      </div>
    )
  }
}
