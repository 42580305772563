import { Component, Prop } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import './style.scoped.scss'

interface ITemplateProps {
  title: string
  hex: string
  image: string
  selected?: boolean
}

@Component
export default class CourseSelectTemplate extends tsx.Component<
  ITemplateProps
> {
  @Prop({ type: String, required: true })
  readonly title!: string

  @Prop({ type: String, required: true })
  readonly hex!: string

  @Prop({ type: String, required: true })
  readonly image!: string

  @Prop({ type: Boolean, default: false })
  readonly selected!: boolean

  protected render() {
    return (
      <div
        class={[
          'd-flex',
          'align-items-center',
          'course-option',
          { selected: this.selected },
        ]}
      >
        <div
          class="image-wrapper d-flex align-items-center justify-content-center mr-50"
          style={`background: ${this.hex}`}
        >
          <img src={this.image} alt="" />
        </div>
        <div class="title">{this.title}</div>
      </div>
    )
  }
}
