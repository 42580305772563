import CourseCell from '@/components/WayupTable/cellTemplates/Course'
import UserCell from '@/components/WayupTable/cellTemplates/User'
import WayupTable, { IWayupTableRef } from '@/components/WayupTable'
import axios from '@/libs/axios'
import { AxiosResponse } from 'axios'
import { CellData, Column, FetchFunction } from '@/components/WayupTable/types'
import { Component, Prop, Ref } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import { BButton } from '../bootstrap'
import { showError } from '@/helpers/notifications'

type Payment = {
  paymentId: number
  orderId: number
  date: string
  payment: number
  paymentSystem: string
  status: string
  paymentLeft: number
  student: {
    id: number
    email: string
    avatarUrl: string
  }
  course: {
    id: number
    abbreviation: string
    previewUrl: string
    color: string
  }
  stream: {
    id: number
    title: string
  }
  refund: {
    status: false
    date: string
    comment: string
  }
  comment?: string
  mentor?: {
    id: number
    fullName: string
    avatar: string
  }
}

export interface IPaymentTableRef {
  refresh: () => Promise<void>
}

interface IPaymentTableProps {
  studentId?: number | null
  orderId?: number | null
  searchEnabled?: boolean
  courseId?: number | null
  startDate?: string
  endDate?: string
}

@Component
export default class PaymentsTable extends tsx.Component<IPaymentTableProps> {
  @Ref() readonly paymentsTable!: IWayupTableRef

  @Prop({ type: Number })
  readonly studentId?: number | null
  @Prop({ type: Number })
  readonly orderId?: number | null
  @Prop({ type: Number })
  readonly courseId?: number | null
  @Prop({ type: String })
  readonly startDate?: string | null
  @Prop({ type: String })
  readonly endDate?: string | null
  @Prop({ type: Boolean, default: true })
  readonly searchEnabled!: boolean

  columns: Column<Payment>[] = [
    {
      field: 'paymentId',
      title: 'id платежа',
      centered: true,
      sortable: true,
    },
    {
      field: 'date',
      title: 'Дата',
      type: 'dateTime',
      sortable: true,
      noWrap: true,
    },
    {
      field: 'payment',
      title: 'Сумма',
      centered: true,
      sortable: true,
    },
    {
      field: 'paymentSystem',
      title: 'Платежная система',
      centered: true,
      sortable: true,
    },
    {
      field: 'status',
      title: 'Статус',
      centered: true,
      sortable: true,
      filter: { field: 'isMade', path: 'isMade' },
    },
    {
      field: 'orderId',
      title: 'id заказа',
      centered: true,
      sortable: true,
      filter: true,
    },
    {
      field: 'student',
      title: 'Студент',
      sortable: true,
      filter: { field: 'studentId', path: 'student.id' },
    },
    {
      field: 'course',
      title: 'Курс',
      sortable: true,
      filter: { field: 'courseId', path: 'course.id' },
    },
    {
      field: 'stream',
      title: 'Поток',
      sortable: true,
      filter: { field: 'streamId', path: 'stream.id' },
    },
    {
      field: 'paymentLeft',
      title: 'Осталось',
      centered: true,
      sortable: true,
    },
    {
      customField: 'buttons',
      centered: true,
    },
  ]

  get uid() {
    return (this as any)._uid
  }

  get filteredColumns() {
    if (!this.studentId && !this.orderId) {
      return this.columns
    } else {
      this.columns.forEach(c => {
        c.filter = false
      })
      if (this.studentId) {
        return this.columns.filter(c => c.field !== 'student')
      }
      if (this.orderId) {
        return this.columns.filter(
          c => c.field !== 'orderId' && c.field !== 'student',
        )
      }
    }
  }

  async refresh() {
    await this.paymentsTable.refresh()
  }

  loadPayments: FetchFunction<Payment> = async (
    page,
    perPage,
    search,
    sort,
    filter,
  ) => {
    const orderBy = sort
      ? `&orderBy=[{"${sort.field.toString()}":"${sort.dir}"}]`
      : ''

    const searchQuery = search ? `&searchQuery=${search}` : ''

    let filterQuery = filter
      ? `&filterOption=${filter.field}&filter=[${filter.value}]`
      : ''

    //непонятный баг, иначе не видит актуальные пропсы
    const props = (this as typeof this & { _props: IPaymentTableProps })._props
    const studentId = props.studentId
    const orderId = props.orderId
    const courseId = props.courseId
    const startDate = props.startDate
    const endDate = props.endDate

    if (studentId) {
      filterQuery = `&filterOption=studentId&filter=[${studentId}]`
    }
    if (orderId) {
      filterQuery = `&filterOption=orderId&filter=[${orderId}]`
    }
    if (courseId) {
      filterQuery = `&filterOption=courseId&filter=[${courseId}]`
    }

    const startDateQuery = startDate ? `&dateStart=${startDate}` : ''
    const endDateQuery = endDate ? `&dateEnd=${endDate}` : ''

    const response: AxiosResponse<{
      payments: Payment[]
      paymentsCount: number
    }> = await axios.get(
      `v1/payments?offset=${
        (page - 1) * perPage
      }&limit=${perPage}${orderBy}${searchQuery}${filterQuery}${startDateQuery}${endDateQuery}`,
    )

    return {
      rows: response.data.payments,
      totalRows: response.data.paymentsCount,
    }
  }

  async deletePayment(row: Payment) {
    const result = await this.$bvModal.msgBoxConfirm(
      `Вы действительно хотите удалить платеж №${row.paymentId} студента ${row.student.email} на сумму ${row.payment}$?`,
      {
        title: 'Подтверждение удаления',
        centered: true,
        okTitle: 'Удалить',
        cancelTitle: 'Отмена',
      },
    )

    if (result) {
      try {
        await axios.delete(`v1/payments/${row.paymentId}`)
        this.refresh()
      } catch (error) {
        console.error(error)
        showError('При удалении платежа произошла ошибка')
      }
    }
  }

  cellTemplate(cellData: CellData<Payment>) {
    if (cellData.column.customField === 'buttons') {
      return (
        <BButton
          onClick={() => this.deletePayment(cellData.row)}
          variant="outline-danger"
        >
          <feather-icon icon="TrashIcon" size="16" />
        </BButton>
      )
    }

    switch (cellData.column.field) {
      case 'student':
        return (
          <UserCell
            avatar={cellData.row.student.avatarUrl}
            firstRow={cellData.row.student.email}
          />
        )
      case 'payment':
      case 'paymentLeft':
        return <div>{`${cellData.value}$`}</div>
      case 'course':
        return (
          <CourseCell
            title={cellData.row.course.abbreviation}
            image={cellData.row.course.previewUrl}
            color={cellData.row.course.color}
          />
        )
      case 'stream':
        return (
          <div>{cellData.row.stream ? cellData.row.stream.title : '-'}</div>
        )
      case 'status':
        return this.statusBadge(cellData)
      case 'paymentSystem':
        return (
          <div>
            <span id={`row-${this.uid}-${cellData.row.paymentId}`}>
              {cellData.row.paymentSystem}
            </span>
            {cellData.row.paymentSystem === 'custom' && cellData.row.mentor && (
              <b-popover
                target={`row-${this.uid}-${cellData.row.paymentId}`}
                triggers="hover"
                placement="right"
                scopedSlots={{
                  title: () => (
                    <b-media
                      vertical-align="center"
                      class="d-flex align-items-center"
                      scopedSlots={{
                        aside: () => (
                          <b-avatar src={cellData.row.mentor!.avatar} />
                        ),
                      }}
                    >
                      <span>{cellData.row.mentor!.fullName}</span>
                    </b-media>
                  ),
                }}
              >
                {cellData.row.comment}
              </b-popover>
            )}
          </div>
        )
    }
  }

  statusBadge(cellData: CellData<Payment>) {
    switch (cellData.row.status) {
      case 'approved':
      case 'ACTIVE':
      case 'signed':
      case 'SUCCESS':
      case 'success':
      case 'complete':
      case 'payment.succeeded':
        return (
          <b-badge
            id={`status-${this.uid}-${cellData.row.paymentId}`}
            variant="success"
          >
            approved
            <b-tooltip target={`status-${this.uid}-${cellData.row.paymentId}`}>
              {cellData.row.status}
            </b-tooltip>
          </b-badge>
        )
      case 'processing':
        return <b-badge variant="primary">processing</b-badge>
      case 'rejected':
      case 'expired':
      case 'FAIL':
      case 'payment.canceled':
        return (
          <b-badge
            id={`status-${this.uid}-${cellData.row.paymentId}`}
            variant="danger"
          >
            rejected
            <b-tooltip target={`status-${this.uid}-${cellData.row.paymentId}`}>
              {cellData.row.status}
            </b-tooltip>
          </b-badge>
        )
      default:
        return <b-badge variant="secondary">{cellData.row.status}</b-badge>
    }
  }

  protected render() {
    return (
      <WayupTable
        ref="paymentsTable"
        columns={this.filteredColumns}
        paginationEnabled
        searchEnabled={this.searchEnabled}
        fetchFunction={this.loadPayments}
        scopedSlots={{ default: cellData => this.cellTemplate(cellData) }}
      />
    )
  }
}
