import { Vue, Component, Prop } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'

@Component
export default class UserCell extends Vue {
  _tsx!: tsx.DeclareProps<tsx.AutoProps<UserCell>>

  @Prop({ type: String })
  readonly avatar?: string | null
  @Prop({ type: String, required: true })
  readonly firstRow!: string
  @Prop({ type: String })
  readonly secondRow?: string

  protected render() {
    return (
      <b-media
        vertical-align="center"
        class="d-flex align-items-center"
        scopedSlots={{ aside: () => <b-avatar src={this.avatar} /> }}
      >
        <div>
          <div>{this.firstRow}</div>
          {this.secondRow && <small>{this.secondRow}</small>}
        </div>
      </b-media>
    )
  }
}
