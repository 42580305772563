


































import { Vue, Component, Ref } from 'vue-property-decorator'
import PaymentsTable from '@/components/payments/PaymentsTable'
import { showError } from '@/helpers/notifications'
import axios from '@/libs/axios'

@Component({ components: { PaymentsTable } })
export default class StudentPayments extends Vue {
  @Ref() readonly paymentsTable!: { refresh: () => Promise<void> }

  amount: number | null = null
  comment = ''
  email = ''
  orderId = 0

  show({ orderId, email }: { orderId: number; email: string }) {
    this.orderId = orderId
    this.email = email
    this.$bvModal.show('order-payments-modal')
  }

  async addPayment() {
    if (!this.amount || this.amount < 1) {
      showError('Введите сумму')
      return
    }

    if (!this.comment) {
      showError('Введите комментарий')
      return
    }

    const result = await this.$bvModal.msgBoxConfirm(
      `Добавить платеж $${this.amount} к заказу №${this.orderId}?`,
      {
        title: 'Подтвердите действие',
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Продолжить',
        cancelTitle: 'Отмена',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      },
    )

    if (result) {
      try {
        await axios.post('v1/payments', {
          order_id: this.orderId,
          amount: this.amount,
          comment: this.comment,
        })
        this.amount = null
        this.comment = ''
        this.$emit('refresh')
        await this.paymentsTable.refresh()
      } catch (error) {
        showError('Ошибка при добавлении платежа')
      }
    }
  }
}
