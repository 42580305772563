

















































import { Vue, Component } from 'vue-property-decorator'
import { BModal, VBTooltip } from 'bootstrap-vue'
import { showError } from '@/helpers/notifications'
import axios from '@/libs/axios'
import { AxiosResponse } from 'axios'

interface IProgressData {
  courseTitle: string
  streamTitle: string
  sprints: Array<{
    id: number
    title: string
    lessons: Array<{
      id: number
      title: string
      date_start: string
      passed: boolean
      passingDate: string
    }>
  }>
}

@Component({
  components: {
    BModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
})
export default class StudentProgressModal extends Vue {
  studentName = ''
  progress: IProgressData | null = null

  get modalTitle() {
    return `Прогресс студента ${this.studentName}`
  }

  get lessons() {
    if (!this.progress) {
      return []
    }

    const lessons = []

    for (const sprint of this.progress.sprints) {
      lessons.push(...sprint.lessons)
    }
    return lessons
  }

  get currentLessonId() {
    if (!this.progress) {
      return
    }

    const lastLessonDate = new Date(
      this.lessons[this.lessons.length - 1].date_start,
    )

    if (new Date().getTime() - lastLessonDate.getTime() > 0) {
      return null
    }

    for (let index = this.lessons.length - 1; index >= 0; index--) {
      const lesson = this.lessons[index]

      const lessonStartDate = new Date(lesson.date_start)
      const currentDate = new Date()

      if (currentDate.getTime() - lessonStartDate.getTime() > 0) {
        return lesson.id
      }
    }
  }

  show(studentId: number, streamId: number, studentName: string) {
    this.studentName = studentName
    this.loadData(studentId, streamId)
    this.$bvModal.show('student-progress-modal')
  }

  formatDate(dateString: string) {
    const date = new Date(dateString)
    return date.toLocaleDateString('ru')
  }

  async loadData(studentId: number, streamId: number) {
    try {
      const response: AxiosResponse<{ data: IProgressData }> = await axios.get(
        `v1/student-progress?studentId=${studentId}&streamId=${streamId}`,
      )

      this.progress = response.data.data
    } catch (error) {
      console.error(error)
      showError('Произошла ошибка при загрузке данных')
    }
  }

  reset() {
    this.studentName = ''
    this.progress = null
  }
}
