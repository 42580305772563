import { Vue, Component, Watch, Ref } from 'vue-property-decorator'
import { BCard, BCol, BRow } from '@/components/bootstrap'
import { showError } from '@/helpers/notifications'
import axios from '@/libs/axios'
import { DateString } from '@/helpers/types/dateString'
import { AxiosResponse } from 'axios'
import Select from '@/components/controls/Select'
import CourseSelectTemplate from '@/components/controls/Select/templates/Course'
import StreamSelectTemplate from '@/components/controls/Select/templates/Stream'
import OrdersTable from '@/components/orders/OrdersTable'
import { saveToLS, loadFromLS, removeFromLS } from '@/helpers/localStorage'

export interface ICourse {
  id: number
  hexColor: string
  imageUrl: string
  title: string
  type: 'free' | 'paid'
}

export interface IMentor {
  id: number
  fullName: string
  avatar?: string
  courses: number[]
}

export interface IStream {
  id: number
  title: string
  dateStart: DateString
  courseId: number
}

const COURSE_KEY = 'ordersTableCourseId'
const STREAM_KEY = 'ordersTableStreamId'

@Component
export default class OrdersPage extends Vue {
  @Ref() readonly ordersTable!: OrdersTable

  courses: ICourse[] = []
  mentors: IMentor[] = []
  streams: IStream[] = []
  selectedCourse: ICourse | null = null
  selectedStream: IStream | null = null
  pending = false
  initialized = false

  get filteredStreams() {
    if (!this.selectedCourse) {
      return []
    }

    return this.streams
      .filter(s => s.courseId === this.selectedCourse!.id)
      .sort((s1, s2) => {
        const date1 = new Date(s1.dateStart).getTime()
        const date2 = new Date(s2.dateStart).getTime()

        return date2 - date1
      })
  }

  @Watch('selectedCourse')
  onCourseChanged() {
    this.selectedStream = null

    if (this.selectedCourse) {
      saveToLS(COURSE_KEY, this.selectedCourse.id)
    } else {
      removeFromLS(COURSE_KEY)
    }
  }

  @Watch('selectedStream')
  onStreamChanged() {
    if (this.selectedStream) {
      saveToLS(STREAM_KEY, this.selectedStream.id)
    } else {
      removeFromLS(STREAM_KEY)
    }
  }

  async mounted() {
    await this.fetchCourses()
    await this.fetchMentors()

    this.loadState()

    setTimeout(() => {
      this.ordersTable.refreshTable()
    }, 10)
  }

  loadState() {
    const courseId = loadFromLS(COURSE_KEY)
    if (courseId) {
      this.selectedCourse = this.courses.find(c => c.id === courseId) || null
    }

    this.$nextTick(() => {
      const streamId = loadFromLS(STREAM_KEY)
      if (streamId) {
        this.selectedStream = this.streams.find(s => s.id === streamId) || null
      }

      this.$nextTick(() => (this.initialized = true))
    })
  }

  async fetchCourses() {
    try {
      const response: AxiosResponse<{
        courses: Array<ICourse & { streams: Omit<IStream, 'courseId'>[] }>
      }> = await axios.get('v1/courses-with-streams')

      this.courses = response.data.courses.map(course => {
        this.streams = this.streams.concat(
          course.streams.map(stream => ({
            ...stream,
            courseId: course.id,
          })),
        )

        const { streams, ...courseWithoutStreams } = course
        return courseWithoutStreams
      })
    } catch (error) {
      console.error(error)
      showError('При загрузке курсов произошла ошибка')
    }
  }

  async fetchMentors() {
    try {
      const response: AxiosResponse<{ mentors: IMentor[] }> = await axios.get(
        'v1/mentors',
      )
      this.mentors = response.data.mentors
    } catch (error) {
      console.error(error)
      showError('При загрузке менторов произошла ошибка')
    }
  }

  protected render() {
    return (
      <BCard no-body class="mb-0">
        <BRow class="mb-50">
          <BCol>
            <Select
              value={this.selectedCourse}
              onInput={value => (this.selectedCourse = value)}
              options={this.courses}
              label="title"
              placeholder="выберите курс"
              emptyFilterMessage="нет подходящих курсов"
              disabled={this.pending}
              scopedSlots={{
                selectedOption: (course: ICourse) => (
                  <CourseSelectTemplate
                    title={course.title}
                    image={course.imageUrl}
                    hex={course.hexColor}
                    selected
                  />
                ),
                option: ({ option }: { option: ICourse }) => (
                  <CourseSelectTemplate
                    title={option.title}
                    image={option.imageUrl}
                    hex={option.hexColor}
                  />
                ),
              }}
            />
          </BCol>
          <BCol>
            <Select
              value={this.selectedStream}
              onInput={value => (this.selectedStream = value)}
              options={this.filteredStreams}
              label="title"
              placeholder="выберите поток"
              emptyFilterMessage="нет подходящих потоков"
              disabled={this.pending || !this.selectedCourse}
              scopedSlots={{
                option: ({ option }: { option: IStream }) => (
                  <StreamSelectTemplate
                    title={option.title}
                    dateStart={option.dateStart}
                  />
                ),
              }}
            />
          </BCol>
        </BRow>

        <OrdersTable
          ref="ordersTable"
          initialized={this.initialized}
          streams={this.streams}
          mentors={this.mentors}
          courses={this.courses}
          selectedStreamId={this.selectedStream?.id}
          selectedCourseId={this.selectedCourse?.id}
          onUpdatePending={value => (this.pending = value)}
        />
      </BCard>
    )
  }
}
