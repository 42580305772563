import { Nullable } from '@/helpers/types/nullable'
import { Component } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import { BInput, BModal } from './bootstrap'

@Component
export default class DeleteConfirmationModal extends tsx.Component<{
  show?: (title: string) => Promise<boolean>
}> {
  confirmationField = ''
  deleteText = ''
  deletePromiseResolve: Nullable<(value: boolean) => void> = null

  get isDeleteConfirmed() {
    return (
      this.confirmationField.toLowerCase() === this.deleteText.toLowerCase()
    )
  }

  async show(title: string) {
    this.deleteText = title
    this.$bvModal.show('modal-delete')

    return new Promise(resolve => (this.deletePromiseResolve = resolve))
  }

  handleOk() {
    if (this.deletePromiseResolve) {
      this.deletePromiseResolve(true)
      this.deletePromiseResolve = null
    }
  }

  clearForm() {
    this.confirmationField = ''
  }

  protected render() {
    return (
      <BModal
        id="modal-delete"
        cancel-variant="outline-secondary"
        ok-only
        ok-title="Я все понимаю, продолжить"
        ok-disabled={!this.isDeleteConfirmed}
        centered
        button-width="w-100"
        title="Требуется подтверждение для удаления"
        onHidden={this.clearForm}
        onOk={this.handleOk}
      >
        <b-form>
          <b-form-group>
            <b-alert show variant="warning">
              <div class="alert-body">
                Отменить данное действие будет невозможно!
              </div>
            </b-alert>

            <b-card-text>
              {`Введите `}
              <span class="font-weight-bolder">{this.deleteText}</span>
              {` , чтобы продолжить`}
            </b-card-text>

            <BInput
              value={this.confirmationField}
              onInput={value => (this.confirmationField = value)}
              class="mt-1"
            />
          </b-form-group>
        </b-form>
      </BModal>
    )
  }
}
