import Tooltip from '@/components/Tooltip'
import { Vue, Component, Prop } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'

@Component
export default class CourseCell extends Vue {
  _tsx!: tsx.DeclareProps<tsx.AutoProps<CourseCell>>

  @Prop({ type: String, required: true })
  readonly image!: string
  @Prop({ type: String, required: true })
  readonly color!: string
  @Prop({ type: String, required: true })
  readonly title!: string
  @Prop({ type: String })
  readonly tooltip?: string

  private uid = -1

  mounted() {
    this.uid = (this as any)._uid
  }

  protected render() {
    return (
      <b-media
        id={`course-${this.uid}`}
        vertical-align="center"
        class="d-flex align-items-center min-width-250"
        scopedSlots={{
          aside: () => (
            <b-aspect
              class="p-50 rounded d-flex align-items-center"
              aspect="1:1"
              style={{ background: this.color }}
            >
              <b-img
                class="rounded"
                center
                width="25"
                height="25"
                src={this.image}
                alt=""
              />
            </b-aspect>
          ),
        }}
      >
        <span>{this.title}</span>

        <Tooltip target={`course-${this.uid}`} tooltip={this.tooltip} />
      </b-media>
    )
  }
}
