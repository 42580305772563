import { Vue, Component, Prop } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'

@Component
export default class Tooltip extends Vue {
  _tsx!: tsx.DeclareProps<tsx.AutoProps<Tooltip>>

  @Prop({ type: String })
  readonly tooltip?: string
  @Prop({ type: String, required: true })
  readonly target!: string

  protected render() {
    if (!this.tooltip) {
      return null
    }

    return (
      <b-tooltip
        target={this.target}
        triggers="hover"
        delay={{ show: 500, hide: 0 }}
      >
        {this.tooltip}
      </b-tooltip>
    )
  }
}
