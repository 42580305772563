import { Component, Prop } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'

interface IRouterLinkProps {
  to: string
}

@Component
export default class Link extends tsx.Component<IRouterLinkProps> {
  @Prop({ type: String })
  readonly to!: string

  protected render() {
    return <router-link to={this.to}>{this.$slots.default}</router-link>
  }
}
