import { Component, Prop, Ref } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import FadeTransition from '../transitions/Fade'
import './style.scoped.scss'

type Option = { label: string; icon?: string; action: () => void }

interface IContextMenuProps {
  options: Option[]
  leftBtn?: boolean
}

@Component
export default class ContextMenu extends tsx.Component<IContextMenuProps> {
  @Ref() readonly contextMenu!: HTMLElement

  @Prop({ type: Array, required: true })
  readonly options!: Option[]
  @Prop({ type: Boolean, default: false })
  readonly leftBtn!: boolean

  parent: HTMLElement | null = null
  x = 0
  y = 0
  visible = false

  mounted() {
    this.parent = this.$el.parentElement
    if (this.parent) {
      if (this.leftBtn) {
        this.parent.addEventListener('mousedown', this.onMouseDown)
      } else {
        this.parent.addEventListener('contextmenu', this.onMouseDown)
      }
      window.addEventListener('scroll', this.onScroll)
      document.addEventListener('mousedown', this.onDocumentClick)
    }
  }

  beforeDestroy() {
    this.visible = false
    this.parent?.removeEventListener('contextmenu', this.onMouseDown)
    this.parent?.removeEventListener('mousedown', this.onMouseDown)
    window.removeEventListener('scroll', this.onScroll)
    document.removeEventListener('mousedown', this.onDocumentClick)

    if (this.contextMenu) {
      document.body.removeChild(this.contextMenu)
    }
  }

  onScroll() {
    this.visible = false
  }

  onDocumentClick(e: MouseEvent) {
    if (this.leftBtn) {
      const path = e.composedPath()
      if (path.includes(this.parent!)) {
        return
      }
    }

    this.visible = false
  }

  onMouseDown(e: MouseEvent) {
    if (this.leftBtn && e.button !== 0) {
      return
    }

    this.show(e.clientX, e.clientY)
    e.preventDefault()
  }

  show(x: number, y: number) {
    this.x = x + 1
    this.y = y + 1

    this.visible = true
    this.$nextTick(() => {
      document.body.appendChild(this.contextMenu)
    })
  }

  protected render() {
    if (!this.visible) {
      return null
    }

    return (
      <FadeTransition duration={0.1}>
        <ul
          ref="contextMenu"
          class="context-menu shadow rounded-lg"
          style={{ top: `${this.y}px`, left: `${this.x}px` }}
        >
          {this.options.map(o => (
            <li onClick={o.action}>
              <div class="icon">
                {!!o.icon && <feather-icon icon={o.icon} size="20" />}
              </div>
              {o.label}
            </li>
          ))}
        </ul>
      </FadeTransition>
    )
  }
}
